import React from "react";
import { Layout, Seo } from "components/common";

const NotFound = () => (
  <Layout>
    <Seo title="404: Not found" location="/404" />
    <h1>Es wurde nichts gefunden.</h1>
    <a href="/">Zurück zur Startseite</a>
    <div style={{height:'40px'}}></div>
    <p>You just hit a route that doesn&#39;t exist.</p>
    <a href="/">Back to the Landing page</a>
  </Layout>
);

export default NotFound;
